<template>
  <div class="container">
    <header-back name='添加账号'></header-back>
    <section class="top44">

      <van-form @submit="onSubmit">
        <div class="account-cen">
          <van-field
            v-model="name"
            name="name"
            label=""
            clearable
            placeholder="请输入昵称"
            :rules="[{ required: true, message: '请输入昵称' }]"
          >
            <template #left-icon>
              <van-icon
                name="friends"
                size="0.6rem"
                color='#3B9DFF'
              />
            </template>
          </van-field>
          <van-field
            v-model="username"
            name="username"
            label=""
            placeholder="请设置账号"
            clearable
            :rules="[{ validator, message: '账号只允许数字和英文' }]"
          >
            <template #left-icon>
              <van-icon
                name="manager"
                size="0.6rem"
                color='#00AA88'
              />
            </template>
          </van-field>
          <van-field
            v-model="password"
            name="password"
            label=""
            clearable
            placeholder="请设置密码"
            :rules="[{ validator:validatorPaw, message: '密码只允许数字、英文、特殊字符' }]"
          ><template #left-icon>
              <van-icon
                name="lock"
                size="0.6rem"
                color='#3259CE'
              />
            </template></van-field>
        </div>
        <div class="add-cen">
          <div class="add-cen-left">
            <div
              class="add-cen-m"
              @click="onDownload"
            >
              <van-icon
                name="records"
                size="0.8rem"
                color="#B5D0FF"
              />

              <p>下载批量上传模版</p>
            </div>
          </div>
          <div class="add-cen-right">
            <div
              class="add-cen-m"
              @click="onUpload"
            >
              <van-icon
                name="description"
                size="0.8rem"
                color='#58E2D8'
              />
              <p>上传excel</p>
            </div>
            <input
              type="file"
              ref="uploadFile"
              id="uploadFile"
              accept=".xlsx, .xls,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              @change="handkeFileChange"
            />
          </div>
        </div>
        <div style="margin: 24px;">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            color="linear-gradient(to right, #F67743,#F75D22)"
          >确认</van-button>
        </div>
      </van-form>

    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import API from "../../api/http";
import validateRule from "@/utils/validateRule";
export default {
  components: { HeaderBack },
  data() {
    return {
      name: "",
      username: "",
      password: "",
      itemFile: {},
      downloadExcelSrc: "/static/sheet.xlsx",
    };
  },

  created() {},

  methods: {
    validator(val) {
      return validateRule.checkOnpaste(val);
    },
    validatorPaw(val) {
      console.log("kk", validateRule.checkOnpaste(val));
      return validateRule.checkOnpaste(val);
    },
    onSubmit(values) {
      if (!values.name.trim()) {
        Toast("请输入昵称");
        return;
      }
      if (!values.username.trim()) {
        Toast("请设置账号");
        return;
      }
      if (!values.password.trim()) {
        Toast("请设置密码");
        return;
      }
      // if (!validateRule.checkOnpaste(values.username)) {
      //   Toast("设置账号只能是数字和英文");
      //   return;
      // }

      API.addTeanm({
        name: this.name.trim(),
        username: this.username.trim(),
        password: this.password.trim(),
      }).then((res) => {
        Toast("添加成功");
        this.$router.go(-1);
      });
    },
    download(url, filename) {
      return fetch(url)
        .then((res) => {
          res.blob().then((blob) => {
            let a = document.createElement("a");
            let url = URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            // window.URL.revokeObjectURL(url);
          });
        })
        .catch(() => {
          Toast("下载失败");
        });
    },

    onDownload() {
      //下载
      let a = document.createElement("a");
      a.href = "./static/sheet.xlsx";
      a.download = "模板.xlsx";
      document.body.appendChild(a);
      console.log(a);
      a.click();
      document.body.removeChild(a);

      // if (this.downloadExcelSrc === "") return;

      // this.download("./static/sheet.xlsx", "模板.xlsx");
    },
    onUpload() {
      //上传
      document.getElementById("uploadFile").click();
    },
    handkeFileChange(e) {
      const files = e.target.files;
      let itemFile = files[0]; // only use files[0]

      if (!this.itemFile) {
        Toast("error", "未上传任何文件");
        return;
      }

      this.$refs["uploadFile"].value = null; // fix can't select the same excel
      this.itemFile = itemFile;
      const file = new FormData();
      // filename是键，file是值，就是要传的文件
      file.append("file", this.itemFile);
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      API.downTeamEcxcel(file).then((res) => {
        Toast.clear();
        Toast("批量添加成功");
        console.log("kk", res);
      });
      console.log("itemFile", itemFile);
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 16px 15px;
  box-sizing: border-box;
}
.account-cen {
  overflow: hidden;
  & > .van-cell {
    margin-bottom: 16px;
    border-radius: 6px;
  }
  .van-icon {
    margin-right: 10px;
  }
}
.add-cen {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  & > div {
    flex: 1;
    box-sizing: border-box;
    padding: 0 6px;
  }
  p {
    padding-top: 10px;
  }
  .add-cen-m {
    background-color: #fff;
    border-radius: 6px;
    padding: 20px;
  }
  #uploadFile {
    display: none;
  }
}
</style>
